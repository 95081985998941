import React, { useCallback } from "react"

import OfferListItem from "./OfferListItem"

import "./OfferList.css"

const OfferList = ({ offers, consumptionData, heading, operator, sortingFn, consumption }) => {
  const renderOffers = useCallback(() => {
    let offersToRender = [...offers];

    if (sortingFn) {
      offersToRender = offersToRender.sort(sortingFn);
    }

    return offersToRender.map(offer => {
      return (
        <OfferListItem
          key={offer.id}
          offer={offer}
          consumptionData={consumptionData}
          operator={operator}
          consumption={consumption}
        />
      )
    });
  }, [sortingFn, offers, operator, consumptionData, consumption]);

  return (
    <section className="offers vertical--spacing">
      <h2>{heading}</h2>
      <p>
        Въз основа на месечна консумация {consumptionData.consumption} кВтч. и
        инсталирана мощност {consumptionData.instPower}
        кВт., без включени{" "}
        <a href="/kompensacii-za-visokite-ceni-na-elektroenergiyata">
          компенсации
        </a>
      </p>
      <div className="offers-list">
        {renderOffers()}
      </div>
    </section>
  )
}

export default OfferList

import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import ElectricityMeterForm from "../components/organisms/electricity-meter-form/ElectricityMeterForm"
import OfferList from "../components/organisms/offers/OfferList"

import calculateOffers from "../helpers/calculatePrice"
import * as constants from "../helpers/constants"
import roundNumber from "../helpers/roundNumber"
import Carousel from "../components/Carousel/BusinessCardsCarousel"

import "./electricity-meter.css"

const ElectricityMeterPage = ({ location }) => {
  const [state, setState] = useState({
    curentLocation: location,
  })

  const params = new URLSearchParams(state.curentLocation.search)

  // Parse query parameters
  const initialDayConsumption = params.get("day")
  const initialNightConsumption = params.get("night")
  const initialMeter = params.get("meter")
  const initialLevel = params.get("level")
  const initialInstalledPower = params.get("instPower")
  const initialConsumptionLv =
    initialDayConsumption && initialNightConsumption
      ? (Number(initialDayConsumption) + Number(initialNightConsumption)) /
      constants.MONEY_TO_KW_MULTIPLIER
      : Number(params.get("consumptionlv")) || 0

  // TODO: Read the query param consumption first
  const consumptionParam = params.get("consumption")
  const initialConsumption =
    initialDayConsumption && initialNightConsumption
      ? Number(initialDayConsumption) + Number(initialNightConsumption)
      : consumptionParam
        ? consumptionParam
        : roundNumber(initialConsumptionLv * constants.MONEY_TO_KW_MULTIPLIER) ||
        0
  const initialOperator = params.get("operator") || constants.defaultOperator

  // Set initial data for precalculation and populating form
  const initialData = {
    consumption: roundNumber(initialConsumption),
    consumptionlv: roundNumber(initialConsumptionLv),
    meter: initialMeter || constants.defaultМeter,
    level: initialLevel || constants.defaultLevel,
    day:
      initialDayConsumption ||
      Math.round(initialConsumption * (1 - constants.dayNightProportion)),
    night:
      initialNightConsumption ||
      Math.round(initialConsumption * constants.dayNightProportion),
    instPower: initialInstalledPower || constants.defaultInstalledPower,
    operator: initialOperator,
  }

  const initialDataStatic = {
    consumption: 500,
    meter: constants.defaultМeter,
    level: constants.defaultLevel,
    day: Math.round(500 * (1 - constants.dayNightProportion)),
    night: Math.round(500 * constants.dayNightProportion),
    instPower: constants.defaultInstalledPower,
    operator: initialOperator,
  }

  const onFormSubmit = data => {
    setState({ curentLocation: new URL(data) })
  }

  const offers = useStaticQuery(graphql`
    {
      allContentfulDistributorOffer(filter: { node_locale: { eq: "bg" } }) {
        nodes {
          id
          offerName
          slug
          contract {
            filename
          }
          offerConditions {
            ... on ContentfulDistributorOfferCondition {
              title
              description {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
          details {
            price
            internal {
              content
            }
            operator
            market
            type
          }
          greenEnergy
          specialOffer
          distributor {
            title
            slug
            website
            logo {
              gatsbyImageData(
                width: 400
                height: 400
                placeholder: BLURRED
              )
            }
            contractSigned
          }
        }
      }

      allContentfulLayoutBusinessCardsBusinessCard(
        filter: { node_locale: { eq: "bg" } }
      ) {
        nodes {
          title
          size
          consumption
          icon {
            file {
              url
            }
          }
          iconActive {
            file {
              url
            }
          }
          background
        }
      }
    }
  `)

  // Initial offers calculation
  let cleanOffers = offers.allContentfulDistributorOffer.nodes.filter(offer => {
    if (offer.details !== null) return true
    else return false
  })
  let calculatedOffers = calculateOffers(cleanOffers, initialData)
  let calculatedOffersStatic = calculateOffers(cleanOffers, initialDataStatic)
  let businessCards = offers.allContentfulLayoutBusinessCardsBusinessCard.nodes

  const sortOffers = (firstOffer, secondOffer) => {
    if (firstOffer.contract) {
      if (secondOffer.contract) {
        return firstOffer.totalPrice - secondOffer.totalPrice;
      }

      return -1;
    }
    return firstOffer.totalPrice - secondOffer.totalPrice;
  }

  return (
    <Layout>

      <Seo
        title="Калкулатор | Сравнете доставчици на електроенергия"
        description="Сравнете доставчици на електроенергия и изберете най-изгодния за
вас. Сменете своя доставчик и спестете от цената за ток."
      />
      <section className="background-gray">
        <div className="container vertical--spacing">
          <div className="row">
            <div className="col-md-4">
              <ElectricityMeterForm
                initialData={initialData}
                onFormSubmit={onFormSubmit}
              />
            </div>
            <div className="col-md-8">
              <div className="row heading__section">
                <div className="col-md-12"><h4 className="text-uppercase">Примерни бизнеси</h4></div>
                {businessCards && <Carousel items={businessCards} initialData={initialData} />}
              </div>
              {initialData.consumption > 0 ? (
                <OfferList
                  offers={calculatedOffers}
                  consumptionData={initialData}
                  heading="Планове и цени"
                  operator={initialOperator}
                  consumption={consumptionParam}
                  sortingFn={sortOffers}
                />
              ) : (
                <OfferList
                  offers={calculatedOffersStatic}
                  consumptionData={initialDataStatic}
                  heading='Примерни планове и цени'
                  operator={initialOperator}
                  consumption={consumptionParam}
                  sortingFn={sortOffers}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ElectricityMeterPage

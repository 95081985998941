import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import styled from "styled-components"
import Box from "@mui/material/Box"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import InputAdornment from "@mui/material/InputAdornment"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import OperatorsBase from "../../atoms/select/OperatorsBase"
import RadioInput from "../../atoms/inputs/RadioInput"

import { MONEY_TO_KW_MULTIPLIER } from "../../../helpers/constants"
import roundNumber from "../../../helpers/roundNumber"
import colors from "../../../styled/colors"
import {
  CALCULATOR_CONSUMATION_TEXT
} from "../../../helpers/constants"

const BLANK_CONSUMPTION_LV = "---"

const useStyles = makeStyles(
  theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
  { index: 1 }
)

const BoxStyle = styled(Box)`
  margin-bottom: 2rem;
  box-shadow: 0px 1px 3px #00000066 !important;
`
const BoxContainer = styled.div`
  padding: 2rem;
  font-weight: 600;
`

const OutlinedInputStyle = styled(OutlinedInput)`
  background-color: white;

  .MuiOutlinedInput-input {
    font-weight: 600;
  }
`

const ItemLink = styled.a`
  align-items: start;
  margin-bottom: 30px;
  
  :hover {
    border-bottom: none;
  }
`

const ElectricityMeterForm = ({ initialData, onFormSubmit }) => {
  const classes = useStyles()

  const initialState = {
    consumption: "",
    consumptionlv: "",
    meter: "e",
    level: "l",
    day: "",
    night: "",
    instPower: "10",
    operator: "",
    showMoreSection: false,
    ...initialData,
  }

  const [state, setState] = useState(initialState)
  const [cachedState, setCachedState] = useState(initialState)
  const [firstChanged, setFirstChanged] = useState("");

  const handleChange = e => {
    const { name, value } = e.target

    switch (name) {
      case "consumption": {
        setState(prevState => ({
          ...prevState,
          [name]: value,
          consumptionlv: BLANK_CONSUMPTION_LV,
          day: value * 0.6,
          night: value * 0.4
        }))

        break
      }

      case "consumptionlv": {
        setState(prevState => ({
          ...prevState,
          [name]: value,
          consumption: roundNumber(value * MONEY_TO_KW_MULTIPLIER),
        }))

        break
      }

      case "night":
      case "day": {
        setState(prevState => {
          const { consumption } = prevState;

          const dayAndNightArr = ['day', 'night'];
          const otherConsumption = dayAndNightArr.filter(x => x !== name)[0];

          if ((firstChanged && firstChanged !== name) || (Number(value) > Number(consumption))) {
            return {
              ...prevState,
              [name]: value,
              consumption: Number(value) + Number(prevState[otherConsumption]),
            }
          }

          const updatedState = {
            ...prevState,
            [name]: value,
            [otherConsumption]: Number(consumption) - Number(value),
          };

          return {
            ...updatedState,
            consumptionlv: BLANK_CONSUMPTION_LV,
          }
        })

        break;
      }

      default: {
        setState(prevState => ({
          ...prevState,
          [name]: value,
        }))

        break
      }
    }
  }

  const setLastChangedOnBlur = (e) => {
    const { name, value } = e.target;

    if (firstChanged) {
      return;
    }

    if (Number(cachedState[name]) !== Number(value)) {
      setCachedState(prevState => ({
        ...prevState,
        [name]: value
      }));

      setFirstChanged(name);
    }
  }

  const calculatorSwitch = e => {
    e.preventDefault();
    const boxContainers = document.getElementsByClassName('switch-box');
    for (let index = 0; index < boxContainers.length; index++) {
      const box = boxContainers[index];
      box.style.display = box.style.display === 'none' ? 'block' : 'none';
    }

    if (e.target.tagName === "BUTTON") {
      var button = document.querySelector("button[type='submit']");
      button.click();
    }
  }

  const onSubmit = e => {
    e.preventDefault();
    var searchParams = new URLSearchParams();
    searchParams.set("consumption", state.consumption);
    searchParams.set("operator", state.operator);
    searchParams.set("meter", state.meter);
    if (state.meter === "m") {
      searchParams.set("day", state.day);
      searchParams.set("night", state.night);
    }
    searchParams.set("consumptionlv", state.consumptionlv === "---" ? "" : state.consumptionlv);
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
    window.history.pushState({ path: newurl }, '', newurl);
    onFormSubmit(newurl);
  }

  const onClick = e => {
    var businessCard = document.querySelector(".swiper-slide.active");

    if (businessCard) {
      businessCard.classList.remove("active");
      businessCard.querySelector("img").style.display = "inline-block";
      businessCard.querySelector("img.active").style.display = "none";
    }
  }

  // const handleShowMore = (e => {
  //   e.preventDefault()
  //   setState(prevState => ({
  //     ...prevState,
  //     showMoreSection: true,
  //   }))
  // }

  return (
    <form id="compare" onSubmit={onSubmit}>
      <BoxStyle
        sx={{
          borderTop: 5,
          borderColor: colors.secondary,
          boxShadow: 1,
          borderRadius: 1,
          bgcolor: "background.paper",
        }}
      >
        <BoxContainer className="switch-box">
          <h4 className="heading-4 text-uppercase element--spacing">
            ВАШИЯТ БИЗНЕС
          </h4>

          <FormControl
            fullWidth
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel htmlFor="consumption">Консумация на ток</InputLabel>
            <OutlinedInput
              name="consumption"
              id="consumption"
              value={state.consumption > 0 ? state.consumption : ""}
              onChange={handleChange}
              onBlur={(e) => {
                var form = document.querySelector("form#compare");
                form.dispatchEvent(new Event("submit", { bubbles: true }));
              }}
              type="number"
              endAdornment={
                <InputAdornment position="end">кВтч./мес.</InputAdornment>
              }
              label="Консумация на ток"
              placeholder="Консумация на ток"
            />
          </FormControl>
          <ItemLink
            href={"#"}
            className="list__link text-underline"
            onClick={calculatorSwitch}
          >
            Не знам каква е консумацията
          </ItemLink>
          <OperatorsBase defaultValue={state.operator} onChange={handleChange} />
          <Box
            sx={{
              py: 4
            }}
          >
            <h5 className="heading-5">Тип електромер</h5>
            <div>
              <RadioInput
                id="typeE"
                name="meter"
                value="e"
                label="Еднотарифен"
                onChange={handleChange}
                checked={state.meter === "e"}
              />
              <FormHelperText></FormHelperText>
            </div>
            <div>
              <RadioInput
                id="typeM"
                name="meter"
                value="m"
                label="Двутарифен дневна/нощна"
                onChange={handleChange}
                checked={state.meter === "m"}
              />
              <FormHelperText></FormHelperText>
            </div>
            <div className="row">
              <div className="col-md-12">
                {state.meter === "m" ? (
                  <div id="dayNight" className="row">
                    <div className="col-md-12">
                      <FormControl>
                        <InputLabel htmlFor="day">ДНЕВНА</InputLabel>
                        <OutlinedInput
                          disabled={state.meter !== "m"}
                          name="day"
                          id="day"
                          value={state.day > 0 ? state.day : ""}
                          onChange={handleChange}
                          onBlur={setLastChangedOnBlur}
                          endAdornment={
                            <InputAdornment position="end">кВтч.</InputAdornment>
                          }
                          type="number"
                          label="ДНЕВНА"
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-12">
                      <FormControl>
                        <InputLabel htmlFor="night">НОЩНА</InputLabel>
                        <OutlinedInput
                          disabled={state.meter !== "m"}
                          name="night"
                          id="night"
                          value={state.night > 0 ? state.night : ""}
                          onChange={handleChange}
                          onBlur={setLastChangedOnBlur}
                          endAdornment={
                            <InputAdornment position="end">кВтч.</InputAdornment>
                          }
                          type="number"
                          label="НОЩНА"
                        />
                      </FormControl>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Box>
          <Button
            style={{
              backgroundColor: "#E6401E",
            }}
            type="submit"
            fullWidth
            onClick={onClick}
          >
            Актуализирай
          </Button>
        </BoxContainer>

        <div className="switch-box" style={{ display: "none" }}>
          <BoxContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <h4 className="heading-4 text-uppercase element--spacing">Калкулатор</h4>
              <ItemLink
                href={"#"}
                className="list__link text-underline"
                onClick={calculatorSwitch}
              >
                Откажи
              </ItemLink>
            </div>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="consumptionlv">Сметка за ток</InputLabel>
              <OutlinedInputStyle
                name="consumptionlv"
                id="consumptionlv"
                value={state.consumptionlv > 0 ? state.consumptionlv : ""}
                onChange={handleChange}
                type="number"
                endAdornment={
                  <InputAdornment position="end">лв./мес.</InputAdornment>
                }
                label="Сметка за ток"
                placeholder="Сметка за ток"
              />
            </FormControl>
          </BoxContainer>
          <BoxContainer style={{ backgroundColor: "#F3F3F3" }}>
            <div style={{ marginBottom: "50px" }}>
              <p>{CALCULATOR_CONSUMATION_TEXT.p1}</p>
              <p>{CALCULATOR_CONSUMATION_TEXT.p2}</p>
            </div>
            <Button
              style={{
                backgroundColor: "#E6401E",
              }}
              type="submit"
              fullWidth
              onClick={calculatorSwitch}
            >
              Изчисли консумацията
            </Button>
          </BoxContainer>
        </div>
      </BoxStyle>
    </form>
  )
}

export default ElectricityMeterForm

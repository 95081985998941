import * as React from "react"
import { useState } from "react"
import styled from "styled-components"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination } from "swiper"

import useWindowSize from "../../helpers/useWindowSize"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import { fireGAEvent } from "../../helpers/ga"

// install Swiper modules
SwiperCore.use([Pagination])

const swiperSettingsTablet = {
    slidesPerView: 2,
    spaceBetween: 16,
    speed: 300,
    observer: true,
    observeParents: true,
    resizeObserver: true,
    pagination: {
        clickable: true,
    },
}

const swiperSettingsMobile = {
    slidesPerView: 1,
    speed: 300,
    spaceBetween: 16,
    observer: true,
    observeParents: true,
    resizeObserver: true,
    pagination: {
        clickable: true,
    },
}

const SwiperContainer = styled(Swiper)`
    padding-bottom: 30px !important;
    
    .swiper-slide {
        width: 182px;
        text-align: center;
        background: white;
        box-shadow: 0px 2px 4px #00000029;
    }

    .swiper-slide.active {
        background: var(--secondary-color);
        color: white;
    }

    .swiper-slide.active .consumption {
        background: #D33818;
        color: white;
    }
`
const NoSwiperContainer = styled.div`
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 40px;

    .swiper-slide {
        width: 182px;
        text-align: center;
        background: white;
        box-shadow: 0px 3px 6px #00000029;
    }

    .swiper-slide.active {
        background: var(--secondary-color);
        color: white;
    }

    .swiper-slide.active .consumption {
        background: #D33818;
        color: white;
    }
`
const CardBox = styled.div`
    height: 100%;
    padding-top: 17px;
`

const CardContainer = styled.div`
    height: 100%;
    display: grid;
`

const CardHeadContainer = styled.div`
    padding: 0 10px;
`

const CardHead = styled.div`
    p {
        margin: 0;
    }
`

const CardImg = styled.div`
    img {    
        margin: 0;
    }

    img.active {
        display: none;
    }
`

const CardBody = styled.div`
    align-self: end;

    .consumption {
        margin: 0;
        padding: 10px 0;
        background: #F3F3F3;
        color: var(--secondary-color);
    }
`

const BusinessCardsCarousel = ({ items, initialData }) => {
    const [isDesktop, setDesktop] = useState(true)
    const [isTablet, setTablet] = useState(false)

    const [windowWidth] = useWindowSize()

    React.useEffect(() => {
        if (windowWidth >= 1300) {
            setDesktop(true)
            setTablet(false)
        }
        if (windowWidth <= 1300 && windowWidth >= 768) {
            setDesktop(false)
            setTablet(true)
        }
        if (windowWidth < 768) {
            setDesktop(false)
            setTablet(false)
        }
    }, [windowWidth])
    console.log(isDesktop)
    console.log(isTablet)

    const setNativeValue = (element, value) => {
        const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
        const prototype = Object.getPrototypeOf(element);
        const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

        if (valueSetter && valueSetter !== prototypeValueSetter) {
            prototypeValueSetter.call(element, value);
        } else {
            valueSetter.call(element, value);
        }
    }

    const changeBusiness = (e, title) => {
        var activeBusinessCard = document.querySelector(".swiper-slide.active");
        if (activeBusinessCard) {
            if (activeBusinessCard.isEqualNode(e.currentTarget)) {
                return;
            } else {
                activeBusinessCard.classList.remove("active");
                activeBusinessCard.querySelector("img.active").style.display = "none";
                activeBusinessCard.querySelector("img").style.display = "inline-block";
            }
        }
        var businessCard = e.currentTarget;
        businessCard.classList.add("active");
        businessCard.querySelector("img").style.display = "none";
        businessCard.querySelector("img.active").style.display = "inline-block";
        var value = businessCard.querySelector(".consumption").id;
        var consumptionInput = document.getElementById("consumption");
        setNativeValue(consumptionInput, value);
        consumptionInput.dispatchEvent(new Event("input", { bubbles: true }));
        setTimeout(() => {
            var form = document.querySelector("form#compare");
            form.dispatchEvent(new Event("submit", { bubbles: true }));
        }, "500");

        fireGAEvent("example_business_type_clicked", { bussiness_type: title });
    }

    const list = () => {
        return items.map((card, index) => {
            return (
              <SwiperSlide
                onClick={(e) => changeBusiness(e, card?.title)}
                key={index}
              >
                <CardBox>
                  <CardContainer>
                    <CardHeadContainer>
                      <CardHead>
                        {card.icon?.file.url && (
                          <CardImg>
                            <img src={card.icon?.file.url} alt="icon" />
                          </CardImg>
                        )}
                        {card.iconActive?.file.url && (
                          <CardImg>
                            <img
                              className="active"
                              src={card.iconActive?.file.url}
                              alt="icon"
                            />
                          </CardImg>
                        )}
                        <p>{card?.title}</p>
                        <div className="small-text">{card?.size} кв.м</div>
                      </CardHead>
                    </CardHeadContainer>
                    <CardBody>
                      <p className="consumption" id={card?.consumption}>
                        {card?.consumption} кВтч./мес.
                      </p>
                    </CardBody>
                  </CardContainer>
                </CardBox>
              </SwiperSlide>
            );
        })
    }

    return items.length > 0 ? (
        <div className="col-md-12">
            {!isDesktop && !isTablet && (
                <SwiperContainer {...swiperSettingsMobile}>{list()}</SwiperContainer>
            )}

            {isTablet && (
                <SwiperContainer {...swiperSettingsTablet}>{list()}</SwiperContainer>
            )}

            {isDesktop && (
                <NoSwiperContainer>{list()}</NoSwiperContainer>
            )}
        </div>
    ) : null
}

export default BusinessCardsCarousel
